import { MAX_MOBILE_SCREEN_WIDTH } from '@/consts/max-size-limit';
import { useState, useEffect } from 'react';

export function useIsMobileScreen(): boolean {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < MAX_MOBILE_SCREEN_WIDTH);
            }
        };

        handleResize(); // Run initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
}
