import { cn } from '@/app/utils/cn';
import Image from 'next/image';
import { ReactNode } from 'react';
import InformationHover, { InformationHoverProps } from '../../atoms/information-hover';

export interface BaseIconFormProps {
    name?: string;
    children: ReactNode;
    iconName?: string;
    parentClassName?: string;
    information?: InformationHoverProps;
    error?: string;
}

const BaseIconForm = ({
    name,
    iconName,
    children,
    parentClassName,
    information,
    error
}: BaseIconFormProps) => {
    return (
        <div
            key={name}
            className={cn(
                'flex flex-col border-[1px] border-gray-F2 h-[36px] md:h-[60px]',
                parentClassName
            )}
        >
            <div className="flex h-full w-full">
                {iconName && (
                    <div
                        className={cn(
                            'flex flex-shrink-0 items-center justify-center h-[36px] w-[36px] bg-gray-F2 border-[1px] border-gray-F2',
                            'md:h-[60px] md:w-[60px]'
                        )}
                    >
                        <Image
                            height={24}
                            width={24}
                            src={`/icons/${iconName}.svg`}
                            alt={`${iconName}`}
                        />
                    </div>
                )}
                <div className="flex relative items-center justify-center w-full">
                    {children}
                    {information && (
                        <InformationHover
                            iconName={information.iconName}
                            text={information.text}
                            className="mr-[12px] right-0 h-[30px] w-[30px]"
                            messageClassName={information.messageClassName}
                        />
                    )}
                </div>
            </div>
            <p className="text-red-600 text-xs ml-[40px] md:ml-[65px]">{error}</p>
        </div>
    );
};

export default BaseIconForm;
