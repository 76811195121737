import Image from 'next/image';

interface HeaderProps {
    className?: string;
}

const Header = ({ className }: HeaderProps) => {
    return (
        <div className="w-full">
            <Image
                width={150}
                height={40}
                alt="delfi white"
                src={'/images/delfi-logo-white.png'}
                className={className}
            />

            <div className="mb-[40px]">
                <p className="text-white font-bold text:[16px] md:text-[20px]">
                    La solución de IA para Empresas PYMEs.
                    <br />
                    <span className="text-green-light">
                        Empieza a utilizar Inteligencia Artificial totalmente Gratis.
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Header;
