import IconInfo from '../molecules/info-icon';
import Header from '../molecules/info-header';
import { useIsMobileScreen } from '@/app/hooks/useIsMobileScreen';
import { cn } from '@/app/utils/cn';

const LeftSideInfo = () => {
    const isMobileScreen = useIsMobileScreen();

    return (
        <div
            className={cn(
                'flex flex-1 flex-col bg-dark-blue-bg items-center justify-center px-[37px] py-[20px]',
                'lg:pr-[144px] lg:pl-[115px]'
            )}
        >
            {!isMobileScreen && <Header className="mb-[39px]" />}
            <div className="flex flex-col gap-y-[18px]">
                <IconInfo
                    iconName="gear-green"
                    title="Configuración sin complicaciones"
                    text="Con nuestra plataforma sin servidor, olvídate de configurar o escalar tu IA. Solo carga los datos y nosotros nos encargamos del resto."
                />
                <IconInfo
                    iconName="money-receive"
                    title="Empieza gratis, escala sin esfuerzo"
                    text="Comienza de manera gratuita. Actualiza y paga a medida que necesites escalar."
                />
                <IconInfo
                    iconName="security-protection-shield"
                    title="Seguridad y preparación empresarial"
                    text="Cumple con los requisitos de seguridad y operacionales para llevar tus productos de IA al mercado más rápido."
                />
            </div>
        </div>
    );
};

export default LeftSideInfo;
