import { cn } from '@/app/utils/cn';
import { ReactNode } from 'react';

interface SplitScreenProps {
    leftSide: ReactNode;
    leftWidth?: number;
    rightSide: ReactNode;
    rightWidth?: number;
    inverted?: boolean;
    className?: string;
}

const SplitScreen = ({
    leftSide,
    rightSide,
    leftWidth = 1,
    rightWidth = 1,
    inverted = false,
    className
}: SplitScreenProps) => {
    return (
        <div
            className={cn(
                `flex sm:flex-row ${inverted ? 'flex-col-reverse' : 'flex-col'}`,
                className
            )}
        >
            <div className="flex" style={{ flex: leftWidth }}>
                {leftSide}
            </div>
            <div className="flex" style={{ flex: rightWidth }}>
                {rightSide}
            </div>
        </div>
    );
};

export default SplitScreen;
