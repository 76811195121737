import { useIsMobileScreen } from '@/app/hooks/useIsMobileScreen';
import Image from 'next/image';

interface IconInfoProps {
    iconName: string;
    title: string;
    text: string;
}

const IconInfo = ({ iconName, title, text }: IconInfoProps) => {
    const isMobileScreen = useIsMobileScreen();

    function imageLength(): number {
        return isMobileScreen ? 44 : 65;
    }

    return (
        <div className="bg-[#242776] flex flex-row rounded-[10px] pl-[16px] md:pl-[33px] pr-[20px] md:pr-[81px] py-[42px] md:py-[22px] gap-x-[14px] md:gap-x-[55px]">
            <Image
                height={imageLength()}
                width={imageLength()}
                alt={title}
                src={`/icons/${iconName}.svg`}
            />
            <div className="flex flex-col gap-y-[9px]">
                <span className="text-white text-[16px] md:text-[20px] font-bold">{title}</span>
                <span className="text-white text-[14px] md:text-[14px]">{text}</span>
            </div>
        </div>
    );
};

export default IconInfo;
