import { cn } from '@/app/utils/cn';
import BasicButton, { BasicButtonProps, ButtonShape } from '../molecules/basic-button';

export enum Color {
    DARK_BLUE,
    GREEN
}

interface SquareButtonProps extends BasicButtonProps {
    backgroundColor: Color;
}

class Manager {
    color: Color;

    constructor(color: Color) {
        this.color = color;
    }

    getColor(): string {
        switch (this.color) {
            case Color.DARK_BLUE:
                return 'bg-dark-blue-bg hover:bg-dark-blue-2';
            case Color.GREEN:
                return 'bg-green-light hover:bg-[#22a980]';
        }
    }
}

//TODO, costumice more the basic-button. For example, connect enums with icons.
const SquareButton = ({
    backgroundColor,
    loading,
    onClick,
    text,
    className,
    type = 'submit'
}: SquareButtonProps) => {
    const manager = new Manager(backgroundColor);
    return (
        <BasicButton
            shape={ButtonShape.SQUARE}
            text={text}
            enabled={!loading}
            className={cn('md:h-[72px] h-[44px] max-w-[488px] w-full', className)}
            type={type}
            icon="/icons/chevron-right.svg"
            customColor={manager.getColor()}
            onClick={onClick}
            loading={loading}
        />
    );
};

export default SquareButton;
