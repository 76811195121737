import { cn } from '@/app/utils/cn';
import BaseIconForm from './base-form';
import { useState } from 'react';
import Image from 'next/image';
import { InformationHoverProps } from '../../atoms/information-hover';

interface InputIconFormProps extends React.ComponentPropsWithoutRef<'input'> {
    onUpdate?: (text: string) => void;
    enabled?: boolean;
    parentClassName?: string;
    iconName?: string;
    password?: boolean;
    information?: InformationHoverProps;
    error?: string;
}

const InputIcon = ({
    name,
    placeholder,
    required,
    enabled = true,
    onUpdate,
    parentClassName = '',
    className,
    iconName,
    password = false,
    information,
    value,
    maxLength,
    error
}: InputIconFormProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <BaseIconForm
            iconName={iconName}
            parentClassName={parentClassName}
            information={information}
            error={error}
        >
            <input
                name={name}
                required={required}
                placeholder={placeholder}
                disabled={!enabled}
                value={value}
                maxLength={maxLength}
                type={password && !showPassword ? 'password' : 'text'}
                className={cn(
                    'focus:outline-none w-full h-full pl-[10px] text-[12px]',
                    'md:text-[16px] md:pl-[20px]',
                    password ? 'pr-[35px]' : 'pr-[12px]',
                    className
                )}
                onChange={(event) => {
                    if (onUpdate)
                        event.target.value.trim().length
                            ? onUpdate(event.target.value)
                            : onUpdate('');
                }}
            />
            {password && (
                <Image
                    height={24}
                    width={24}
                    className="hover:cursor-pointer mx-[5px] absolute right-1"
                    src={showPassword ? `/icons/eye-on.svg` : `/icons/eye-off.svg`}
                    alt="eye-icon"
                    onClick={() => setShowPassword((current) => !current)}
                />
            )}
        </BaseIconForm>
    );
};

export default InputIcon;
