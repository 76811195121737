import SplitScreen from '@/app/components/atoms/split-screen';
import LeftSideInfo from './components/organisms/left-side-info';
import RightSideCredentials from './components/organisms/right-side-credentials';

const Login = () => {
    return (
        <SplitScreen
            inverted
            leftSide={<LeftSideInfo />}
            rightSide={<RightSideCredentials />}
            className="md:min-h-screen"
        />
    );
};

export default Login;
